<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ displayText('partner.partner_area', '合作夥伴專區') }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active>{{ displayText('partner.partner_management', '合作夥伴管理') }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between mb-4 mb-xl-2">
        <h4 class="mb-2 mb-xl-0 font-weight-bold">
          {{ displayText('partner.partner_management', '合作夥伴管理') }}
        </h4>
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
         <b-button
            v-if="checkPermission([consts.PARTNER_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="exportPartner()"
            >{{ displayText('partner.export_partner', '匯出合作夥伴') }}</b-button>
          <b-button
            v-if="checkPermission([consts.PARTNER_CREATE])"
            v-b-modal.modal-upload
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
          ><i class="fa fa-plus"></i>{{ displayText('partner.import_partner', '批次匯入合作夥伴') }}</b-button
          >
          <b-button
            v-if="checkPermission([consts.PARTNER_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'PartnerCreate' }"
          ><i class="fa fa-plus"></i>{{ displayText('partner.add_partner', '新增合作夥伴') }}</b-button>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input v-bind:placeholder="displayText('partner.search_placeholder', '搜尋名稱、代號')" v-model="query.keyword"
                          v-on:keyup.enter="getPartners()"></b-form-input>
            <b-input-group-append>
              <b-button @click="getPartners()"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button v-b-toggle.collapse-1-inner variant="link" class=" flex-shrink-0 ml-0 ml-xl-1">
            {{ displayText('partner.search_advanced', '進階搜尋') }}<i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form>
            <div class="d-flex flex-column flex-xl-row">
              <!-- 名稱 -->
              <b-form-group v-if="hiddenSearchField('name')" v-bind:label="displayText('partner.name', '名稱')" label-cols-sm="3" label-cols-lg="2" content-cols-lg="9" style="width: 100%">
                <b-form-input v-model="query.name" class="mr-2"></b-form-input>
              </b-form-group>

              <!-- 夥伴代碼 -->
              <b-form-group v-if="hiddenSearchField('partner_code')" v-bind:label="displayText('partner.partner_code', '夥伴代碼')" label-cols-sm="3" label-cols-lg="2" style="width: 100%">
                <b-form-input v-model="query.partner_code" class="mr-2"></b-form-input>
              </b-form-group>
            </div>

            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-bind:label="displayText('partner.location', '地點')"
                label-cols-sm="3"
                label-cols-lg="1"
                style="width: 100%"
                data-bootstrap-style
                v-if="hiddenSearchField('status') && hiddenSearchField('city') && hiddenSearchField('district')"
              >
                <div class="d-flex flex-column flex-xl-row city-selector-set-has-value">
                  <!-- 縣市選單 -->
                  <select v-if="hiddenSearchField('city')" class="form-control form-control-sm county mr-2" v-model="query.city"></select>

                  <!-- 區域選單 -->
                  <select v-if="hiddenSearchField('city') || hiddenSearchField('district')" class="form-control form-control-sm district mr-2" v-model="query.district"></select>

                  <!-- 地址輸入框 -->
                  <b-form-input v-if="hiddenSearchField('status')" v-model="query.address" v-bind:placeholder="displayText('partner.address_placeholder', '請輸入地址')"></b-form-input>
                </div>
              </b-form-group>
            </div>



            <div class="d-flex flex-column flex-xl-row">
              <!-- 是否啟用 -->
              <b-form-group v-if="hiddenSearchField('is_enabled')" v-bind:label="displayText('partner.is_enabled', '是否啟用')" label-cols-sm="3" label-cols-lg="2" style="width: 100%">
                <b-form-checkbox-group v-model="query.is_enabled" class="pt-1">
                  <b-form-checkbox value="1">{{ displayText('partner.enabled', '啟用') }}</b-form-checkbox>
                  <b-form-checkbox value="0">{{ displayText('partner.disabled', '未啟用') }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>

              <!-- 狀態 -->
              <b-form-group v-if="hiddenSearchField('status')" v-bind:label="displayText('partner.status', '狀態')" label-cols-sm="3" label-cols-lg="2" style="width: 100%">
                <b-form-select v-model="query.status" :options="statusOptions" class="mr-2 form-control form-control-sm"></b-form-select>
              </b-form-group>
            </div>

            <div class="flex-column">
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="handleSearch"
              ><i class="fa fa-search"></i>{{ displayText('partner.search', '搜尋') }}</b-button>
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="resetSearch"
              >{{ displayText('partner.search_reset', '搜尋條件重置') }}</b-button>
            </div>

          </b-form>
        </div>
      </b-collapse>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="partners"
                :fields="fields"
                @sort-changed="handleSort"
              >
                <template #cell(actions)="data">
                  <b-button
                    class="ml-2"
                    variant="inverse-info"
                    v-if="checkPermission([consts.PARTNER_WORKER_LIST_VIEW])"
                    :to="{
                      name: 'PartnerWorkerListByPartner',
                      params: { partnerId: data.item.id },
                    }"
                  >{{ displayText('partner.worker_management', '員工管理') }}
                  </b-button>
                  <b-button
                    class="ml-2"
                    variant="inverse-info"
                    v-if="checkPermission([consts.PARTNER_STAFF_LIST_VIEW])"
                    :to="{
                      name: 'PartnerStaffList',
                      params: { partnerId: data.item.id },
                    }"
                  >{{ displayText('partner.staff_list', '帳號管理') }}
                  </b-button>

                  <b-button
                    v-if="checkPermission([consts.PARTNER_LIST_VIEW])"
                    variant="inverse-primary"
                    :to="{
                      name: 'PartnerView',
                      params: { partnerId: data.item.id },
                    }"
                  >
                    {{ displayText('partner.view', '查看') }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.PARTNER_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'PartnerEdit',
                      params: { partnerId: data.item.id },
                    }"
                  >
                   {{ displayText('partner.edit', '編輯') }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.PARTNER_DELETE])"
                    variant="inverse-danger"
                    @click="deletePartner(data.item.id)"
                  >
                    {{ displayText('partner.delete', '刪除') }}
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </b-card-text>
      <div class="container" style="margin-top: 80px">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-auto mb-3">
            <span>目前第 {{ currentPage }} 頁 / 總共 {{ Math.ceil(totalRows / query.per_page) }} 頁</span>
          </div>

          <div class="col-12 col-md-auto mb-3">
            <b-pagination
              class="my-auto"
              v-if="totalRows > 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="query.per_page"
            ></b-pagination>
          </div>

          <div class="col-12 col-md-auto mb-3">
            <span>每頁顯示數量：</span>
            <b-form-select
              v-model="query.per_page"
              :options="perPageOption"
              class="d-inline-block"
              style="width: auto;"

            ></b-form-select>
            <span> / 總數量: {{ totalRows }}</span>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal id="modal-upload" title="批次上傳">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
          <b-form-file plain @input="uploadPartner"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="getSampleExcelFile('partner_import_xls' ,'/excel/partner_list.xlsx')"
        ><i class="fa fa-file-text"></i>下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import partnerApi from "@/apis/partner";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import TwCitySelector from "tw-city-selector";
import { displayText, getSampleExcelFile, } from '@/utils/dashboardUtils'

export default {
  data() {
    return {
      consts: consts,
      perPageOption: [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50},
        {text: "100", value: 100}
      ],
      currentPage: 1,
      totalRows: 1,
      initialized: false,
      showLoading: true,
      showLoadingUpload: false,
      showAdvanceSearch: false,
      twCitySelector: null,
      partners: [],
      partner: null,
      orderBy: null,
      orderDirection: null,
      query: {
        name: null,
        partner_code: null,
        city: "",
        district: "",
        is_enabled: [1],
        status: null,
        page: 1,
        keyword: null,
        order_by: null,
        order_direction: null,
        per_page: 10,
      },
      statusOptions: [
        {text: displayText('partner.status_select', '請選擇狀態'), value: null},
        { text: displayText('partner.status_approved', '通過審核'), value: "approved" },
        { text: displayText('partner.status_pending', '待審核'), value: "pending" },
        { text: displayText('partner.status_rejected', '審核失敗'), value: "rejected" },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),

    queryWatcher() {
      return `${this.query.keyword}-${this.query.page}-${this.query.per_page}-${this.query.order_by}-${this.query.order_direction}`;
    },
    fields() {
      let fields = [
        {
          key: "name",
          label: displayText('partner.name', '名稱'),
          sortable: true,
        },
        {
          key: "partner_code",
          label: displayText('partner.partner_code', '夥伴代碼'),
          sortable: true,
        },
        {
          key: "city",
          label: displayText('partner.city', '城市'),
          sortable: true,
        },
        {
          key: "district",
          label: displayText('partner.district', '鄉鎮市'),
          sortable: true,
        },
        {
          key: "created_at",
          label: displayText('partner.created_at', '建檔日期'),
          sortable: true,
        },
        {
          key: "status",
          label: displayText('partner.status', '狀態'),
          formatter: (value) => {
            if (value === "approved") {
              return displayText('partner.status_approved', '審核通過');
            } else if (value === "pending") {
              return displayText('partner.status_pending', '待審核');
            } else if (value === "rejected") {
              return displayText('partner.status_rejected', '審核失敗');
            }
          },
        },
        {
          key: "is_enabled",
          label: displayText('partner.is_enabled', '啟用'),
          formatter: (value) => {
            return value ? displayText('partner.enabled', '啟用') : displayText('partner.disabled', '未啟用');
          },
          sortable: true,
        },
        {
          key: "actions",
          label: displayText('partner.action', '操作'),
        },
      ];
      const hiddenFields = this.getModuleConfig("partner", "partner.hidden_fields")
      if (hiddenFields) {
        return fields.filter(field => !hiddenFields.includes(field.key))
      }
      return fields
    },
  },
  async created() {
    const newQuery = {
      ...this.query,
      ...this.$route.query,
    };
    newQuery.page = parseInt(newQuery.page, 10) || 1;
    newQuery.is_enabled = newQuery.is_enabled ? [].concat(newQuery.is_enabled) : [];

    this.query = newQuery;
    this.currentPage = newQuery.page;
    this.$store.commit('route/SET_QUERY_PARAMS', this.query);
  },
  watch: {
    'currentPage': function(newPage, oldPage) {
      if (!this.initialized || newPage === oldPage) return;
      this.query.page = newPage;
    },
    'queryWatcher': {
      handler() {
        if (!this.initialized) return;
        this.updateUrl();
        this.getPartners();
      },
      deep: true
    },
  },
  mounted() {
    this.twCitySelector = new TwCitySelector({
      el: ".city-selector-set-has-value",
      elCounty: ".county",
      elDistrict: ".district",
    });
    this.getPartners();
    this.initialized = true;
  },
  methods: {
    displayText,
    getSampleExcelFile,
    hiddenSearchField(fieldName) {
      if (this.getModuleConfig("partner", "partner.hidden_fields")) {
        return !this.getModuleConfig("partner", "partner.hidden_fields").includes(fieldName);
      } else {
        return true
      }
    },
    async getPartners() {
      this.showLoading = true;
      try {
        const params = {
          ...this.query
        };

        const { data } = await partnerApi.getPartners(this.organization, params);
        this.partners = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText('partner.api_failed', '讀取資料錯誤'),
          });
        }
      }
      this.showLoading = false;
    },
    async deletePartner(partnerId) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              let response = await partnerApi.removePartner(this.organization, partnerId);
              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功刪除",
                });
                await this.getPartners();
              }
            } catch (error) {
              if (error.response.data.message) {
                this.$swal.fire({
                  title: '刪除失敗',
                  type: "error",
                  text: error.response.data.message,
                });
              }
            }
          }
        });
    },
    async uploadPartner(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await partnerApi.uploadPartner(this.organization, formData);

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.query.page = 1;
          await this.getPartners();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async exportPartner() {
      let query = this.query
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (query[key] !== null && query[key] !== undefined && query[key] !== "") {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      partnerApi.exportPartner(this.organization, filteredQuery).then(() => {
        this.$swal({
          title: '成功',
          text: "請至資料匯出查看結果",
          type: 'success',
          showCancelButton: true,
          confirmButtonText: '前往查看',
          cancelButtonText: '確認'
        }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            } else {
              return;
            }
          });
      })
    },
    handleSort(ctx) {
      this.query.order_by = ctx.sortBy;
      this.query.order_direction = ctx.sortDesc ? "desc" : "asc";
      this.query.page = 1;
    },
    handleSearch() {
      this.updateUrl()
      this.getPartners();
    },
    resetSearch() {
      this.query = {
        name: null,
        partner_code: null,
        city: "",
        district: "",
        is_enabled: [1],
        status: null,
        page: 1,
        keyword: null,
        order_by: null,
        order_direction: null,
        per_page: 10,
      };
    },
    updateUrl() {
      let query = this.query
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (query[key] !== null && query[key] !== undefined && query[key] !== "") {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      this.$store.commit('route/SET_QUERY_PARAMS', filteredQuery);
      this.$router.push({ path: '', query: filteredQuery }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
